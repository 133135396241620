import React from "react";

import boiImage from "../Assets/boi.JPG"; // Adjust the path as necessary based on your project structure
import resume2 from "../Assets/resume2.pdf";

export default function ComingSoon() {
  return (
    <div className="coming-soon">
      <h2>Coming Soon...</h2>
      <h2>Here is a picture of my dog. </h2>
      <img src={boiImage} alt="dog" />

      <p>
        <a
          href={resume2}
          rel="noopener noreferrer"
          target="_blank"
          className="bg-transparent hover:bg-orange-400  font-semibold hover:text-white p-2 border border-sky-600 hover:border-transparent rounded text-left"
        >
          Download Resume
        </a>
      </p>
    </div>
  );
}
