// App.js
//import { Routes } from "react-router-dom";
// import Home from "./Pages/Home";
// import Projects from "./Pages/Projects";
// import Resume from "./Pages/Resume";
// import Blog from "./Pages/Blog";
//import Blog1 from "./Pages/Blog1";
// import NoMatch from "./Pages/NoMatch";
import ComingSoon from "./Pages/ComingSoon";

import "./App.css";
import "./index.css";
// import Navbar from "./Navigation/Navbar";
// import Footer from "./Navigation/Footer";

export var handleEvent = (e) => {
  document
    .getElementById(e)
    .scrollIntoView({ behavior: "smooth", block: "start" });
};
const App = () => {
  return (
    <>
      {/* <Routes>
    <Blog1 />
    </Routes> */}
      {/* <Navbar />
      <Home />
      <Projects />
      <Resume />
      <Blog />
      <Footer /> */}
      <ComingSoon />
    </>
  );
};

export default App;
